import { CheckIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
const desktopBgAspectRatio = 1099 / 1920;
const mobileBgAspectRatio = 2271 / 1080;

export default function SectionB() {
  const [isMobile] = useMediaQuery("(max-width: 763px)");
  return (
    <Flex
      id="section-b"
      background={{
        base: "url('/images/bg-section-b-mobile.png')",
        md: "url('/images/bg-section-b-desktop.png')",
      }}
      // backgroundSize={isMobile ? "cover" : "100vw"}
      backgroundSize={{ base: "cover", md: "100vw" }}
      backgroundRepeat={"no-repeat"}
      backgroundPosition="bottom"
      width={"full"}
      // h={height}
      h={{
        base: window.innerWidth * mobileBgAspectRatio,
        md: window.innerWidth * desktopBgAspectRatio,
      }}
      position={"relative"}
      bottom={{ base: "60px", md: "180px", "2xl": "130px" }}
      direction={"column"}
      alignItems={"center"}
    >
      <Flex
        width={{ base: "100%", md: "50%" }}
        alignItems={"center"}
        position="relative"
        bottom={{ base: "50px", md: "80px" }}
        direction="column"
        color="gray.50"
      >
        <Image
          src="/images/roupas.png"
          maxW={{ base: "400px", md: "300px", xl: "500px" }}
        />
        <Heading
          textAlign={"left"}
          maxW={isMobile ? "300px" : "full"}
          fontFamily={"Montserrat"}
          fontSize={{ base: "30px", "2xl": "50px" }}
        >
          Roupas limpas, cheirosas e secas!
        </Heading>
      </Flex>
      <Flex w="full" h="full">
        <VStack
          color="gray.50"
          position="relative"
          bottom={{ base: "50px", md: "80px" }}
          width={{ base: "100%", md: "50%" }}
          textAlign={"left"}
          px="40px"
          justifyContent={{ base: "flex-start", md: "center" }}
          h="full"
        >
          <List
            spacing={2}
            fontFamily={"Open Sauce Sans"}
            fontSize={{ base: "15px", lg: "24px", "2xl": "30px" }}
          >
            <ListItem sx={styles.listItem}>
              <ListIcon>
                <CheckIcon />
              </ListIcon>
              Consumo de água e energia reduzido
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListIcon>
                <CheckIcon />
              </ListIcon>
              Sistema de dosagem eficaz
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListIcon>
                <CheckIcon />
              </ListIcon>
              Custo benefício, praticidade & economia
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListIcon>
                <CheckIcon />
              </ListIcon>
              Lavagem em apenas 30 minutos
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListIcon>
                <CheckIcon />
              </ListIcon>
              Secagem em até 45 minutos
            </ListItem>
          </List>
        </VStack>
      </Flex>
    </Flex>
  );
}

const styles = {
  listItem: {
    width: "100%",
    textAlign: "left",
  },
};
