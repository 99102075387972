import {
  Box,
  Flex,
  Image,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import logoLavando from "../assets/logo-Lavando-1.svg";
export default function SectionA() {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  return (
    <Flex
      id="section-a"
      background={"url('/images/landry-1.png')"}
      backgroundSize={{ base: "cover", md: "50vw" }}
      backgroundRepeat={"no-repeat"}
      backgroundPosition={{ base: "top 0%", md: "right 0% top 10%" }}
      width="full"
      height={{ base: "400px", md: "600px", lg: "800px" }}
      marginTop={{ base: "100px", md: "40px" }}
      direction={"column"}
      alignItems={"flex-start"}
    >
      <VStack padding={{ base: "20px", md: "60px 6px 60px 4vw" }}>
        {!isMobile ? (
          <Image
            display={{ base: "flex" }}
            src={logoLavando}
            width="40vw"
            position="relative"
          />
        ) : (
          <Box height="160px" />
        )}
        <VStack alignItems={"flex-start"} fontFamily={"Montserrat"} spacing={1}>
          <Text fontSize={{ base: "18px", md: "32px" }} sx={styles.text}>
            Lave ou seque
          </Text>
          {/* <br /> */}
          <Text fontSize={{ base: "18px", md: "32px" }} sx={styles.text}>
            seu cesto de roupas
          </Text>
          {/* <br /> */}
          <Text fontSize={{ base: "18px", md: "32px" }} sx={styles.text}>
            a partir de R$18,90
          </Text>
        </VStack>
      </VStack>
      {/* <Flex w="full" overflowY={"visible"} position={"relative"} top="-100px">
        <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
          <path
            d="M0,50 C120,100 200,0 500,50 L500,00 L0,0 Z"
            style={{ stroke: "none", fill: "#147c99" }}
            transform="rotate(180, 250, 50)"
          ></path>
        </svg>
      </Flex> */}
    </Flex>
  );
}

const styles = {
  text: {
    color: "gray.50",
    padding: "0px 6px",
    borderRadius: "6px",
    bg: "#13a095",
    fontWeight: 500,
  },
};
