import { Button, Center, Link } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Center
      bg="#dfdfdf"
      position="relative"
      bottom={"0px"}
      height="80px"
      w="full"
      zIndex={0}
    >
      <Button
        color="gray.700"
        variant={"link"}
        as={Link}
        target="_blank"
        href="https://www.instagram.com/_agenciaopen/"
        // position="sticky"
      >
        Site desenvolvido por Agência Open
      </Button>
    </Center>
  );
}
