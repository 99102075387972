import { Button, Flex, Heading, Image, Link, Text } from "@chakra-ui/react";

export default function SectionC() {
  return (
    <Flex
      id="section-c"
      h={{ base: "500px", xl: "350px", "2xl": "500px" }}
      position={"relative"}
      // bottom={{ base: "20px", md: "100px" }}
      background={"url('/images/section-c-bg.png')"}
      backgroundRepeat={"no-repeat"}
      backgroundPosition={{ base: "bottom", md: "bottom 0% left 0%" }}
      backgroundSize={{ base: "contain", md: "50vw" }}
      direction={{ base: "column", md: "row-reverse" }}
      zIndex={9}
    >
      <Flex
        px="40px"
        alignItems="center"
        direction={"column"}
        w={{ base: "100%", md: "55%" }}
        justifyContent={{ base: "flex-start", md: "flex-end" }}
      >
        <Flex
          w={{ base: "300px", xl: "450px" }}
          h={{ base: "160px", xl: "260px" }}
          objectFit={"contain"}
          mb={{ base: "0px", md: "40px" }}
          position={"relative"}
          bottom={{ base: "0px", md: "60px" }}
          direction={"column"}
          textAlign={"left"}
        >
          <Heading fontSize={{ base: "50px", md: "90px" }} fontWeight={"bold"}>
            <span style={{ color: "#144d92" }}>cash</span>
            <span style={{ color: "#99d5eb" }}>back</span>
          </Heading>
          <Text
            fontSize={{ base: "22px", md: "30px" }}
            color="#767777"
            letterSpacing={"0px"}
          >
            Indique um amigo e{" "}
            <span style={{ color: "#13a297" }}>
              ganhe voucher com descontos
            </span>{" "}
            para sua segunda lavagem!
          </Text>
        </Flex>
        <Button
          borderRadius="30px"
          padding="30px"
          w={{ base: "300px", md: "400px" }}
          color="gray.50"
          fontFamily={"Montserrat"}
          fontWeight={400}
          background="linear-gradient(to right, #4da5dc, #3e6796)"
          position={"relative"}
          top={"34px"}
          fontSize={{ base: "22px", md: "22px" }}
          as={Link}
          _hover={{
            bg: "#4da5dc",
          }}
          target="_blank"
          href="https://api.whatsapp.com/send?phone=5571997357171&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20da%20Lavando%2B%20"
        >
          Tire suas dúvidas
        </Button>
      </Flex>
      <Flex
        w={{ base: "100%", md: "40%" }}
        alignItems={"center"}
        justifyContent={"center"}
        pt={{ base: "0px", md: "40px" }}
      >
        <Image
          position="relative"
          top={{ base: "0px", md: "20px" }}
          maxW={{ base: "350px", md: "100%" }}
          src="/images/toalha.png"
        />
      </Flex>
    </Flex>
  );
}
