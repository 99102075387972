//@ts-nocheck
import {
  Button,
  Center,
  Heading,
  Text,
  VStack,
  useMediaQuery,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Instafeed from "instafeed.js";
export default function SectionD() {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  useEffect(() => {
    var feed = new Instafeed({
      get: "user",
      accessToken:
        "IGQWRPZATNrSWU1VXRDaF91dzRvUWs5ZAEpqZAWpoMTVoS3A3VXJYcER2TUNKUkVzLWlzcEJEeUFleVJKbUZA2aDgtZAmZAUQ0Rhd2d4V3hjb0dhOW5CWjNTcllSSXZAkbWUxNGRrRkMtWHpJeGxQQm5lTTNPNzJQUnR2THcZD",
      limit: 9,
    });
    feed.run();
  }, []);
  return (
    <VStack
      id="section-d"
      bg="#13a297"
      width="full"
      // h="500px"
      position={"relative"}
      // bottom={isMobile ? "20px" : "100px"}
      zIndex={0}
      color="gray.50"
      pt={isMobile ? "40px" : "80px"}
      px={"40px"}
    >
      <Heading
        w="full"
        textAlign={isMobile ? "left" : "center"}
        fontFamily={"Montserrat"}
        fontWeight={400}
        fontSize={isMobile ? "20px" : "34px"}
      >
        Estamos em Salvador&nbsp;
        {isMobile && <br />}
        Bairro Costa azul e na Barra
      </Heading>
      <Center>
        <SimpleGrid
          columns={3}
          maxW={"900px"}
          w={"full"}
          justifyContent={"center"}
          spacing={2}
          p={2}
          pt={"20px"}
          id="instafeed"
          className="instafeed-container"
        ></SimpleGrid>
      </Center>
      <Button
        borderRadius="30px"
        bg="#5ed2c6"
        width="320px"
        padding={"32px"}
        // py="12px"
        color="gray.50"
        fontFamily={"Montserrat"}
        fontWeight={400}
        fontSize="22px"
        my="20px"
        as={Link}
        target="_blank"
        href="https://www.instagram.com/lavanderialavandomais/"
      >
        Seguir no Instagram
      </Button>
      <Text pb="12px">contato@lavanderialavandomais.com</Text>
    </VStack>
  );
}
