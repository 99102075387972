import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Image,
  VStack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";

export default function Header() {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  const drawerDisclosure = useDisclosure();
  function scrollToSection(section: string) {
    const targetSection = document.getElementById(section);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth", block: "center" });
      drawerDisclosure.onClose();
    }
  }
  return isMobile ? (
    <Flex
      position={"fixed"}
      w={"full"}
      top={0}
      justifyContent={"space-between"}
      alignItems={"center"}
      height="120px"
      zIndex={11}
      bg="white"
    >
      <Image
        display={{ base: "flex" }}
        src="/images/logo-lavando-1.png"
        width="240px"
        position="relative"
        left="-16px"
      />
      <Button variant={"ghost"} onClick={drawerDisclosure.onOpen} mr="12px">
        <Icon as={HamburgerIcon} width={"30px"} height="30px" />
      </Button>
      <Drawer
        isOpen={drawerDisclosure.isOpen}
        onClose={drawerDisclosure.onClose}
      >
        <DrawerOverlay
          bg="none"
          backdropFilter="auto"
          backdropInvert="40%"
          backdropBlur="2px"
        />
        <DrawerContent>
          <DrawerHeader>
            <Image
              display={{ base: "flex" }}
              src="/images/logo-lavando-1.png"
              width="200px"
              position="relative"
              left="-16px"
            />
            <DrawerCloseButton size={"30px"} top="36px" right="22px" />
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={3} w="full">
              <Button
                h="40px"
                color="gray.800"
                variant={"link"}
                onClick={() => scrollToSection("section-a")}
              >
                LAVANDO+
              </Button>
              <Button
                h="40px"
                color="gray.800"
                variant={"link"}
                onClick={() => scrollToSection("section-b")}
              >
                SERVIÇOS
              </Button>
              <Button
                h="40px"
                color="gray.800"
                variant={"link"}
                onClick={() => scrollToSection("section-c")}
              >
                OFERTA
              </Button>
              <Button
                h="40px"
                color="gray.800"
                variant={"link"}
                onClick={() => scrollToSection("section-d")}
              >
                CONTATO
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  ) : (
    <Center
      bg="#22578e"
      position={"fixed"}
      w={"full"}
      top={0}
      justifyContent={"center"}
      alignItems={"center"}
      height="100px"
      zIndex={10}
    >
      <HStack spacing={16}>
        <Button
          color="gray.50"
          variant={"link"}
          onClick={() => scrollToSection("section-a")}
        >
          LAVANDO+
        </Button>
        <Button
          color="gray.50"
          variant={"link"}
          onClick={() => scrollToSection("section-b")}
        >
          SERVIÇOS
        </Button>
        <Button
          color="gray.50"
          variant={"link"}
          onClick={() => scrollToSection("section-c")}
        >
          OFERTA
        </Button>
        <Button
          color="gray.50"
          variant={"link"}
          onClick={() => scrollToSection("section-d")}
        >
          CONTATO
        </Button>
      </HStack>
    </Center>
  );
}
