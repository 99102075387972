import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import SectionA from "./components/SectionA";
import SectionB from "./components/SectionB";
import SectionC from "./components/SectionC";
import SectionD from "./components/SectionD";
import Footer from "./components/Footer";

function App() {
  return (
    <ChakraProvider>
      <div className="App">
        <Header />
        <SectionA />
        <SectionB />
        <SectionC />
        <SectionD />
        <div style={{ width: "100vw" }}>
          <iframe
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.959737877552!2d-38.4478889!3d-12.9884786!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7161b4838c83615%3A0x8417a460483b55b2!2sLavanderia%20Lavando%20mais!5e0!3m2!1spt-BR!2sbr!4v1700589273505!5m2!1spt-BR!2sbr"
            width={window.innerWidth}
            height="450px"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <Footer />
      </div>
    </ChakraProvider>
  );
}

export default App;
